import React, { useEffect } from 'react';
import { Input, PhoneInput, Select, Button } from '@/components/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type {
    CompanyDetails_jsonld_client_read_companyDetails_read_address_read as Company,
    Client_jsonld_client_read_companyDetails_read_address_read as Client,
    Dealer_dealer_read_company_details_read_dealer_detail_file_read as Dealer,
    User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User,
} from '@expanzi/api-types';
import { useTranslation } from '@/lib/i18n';
import type { CountryCodelist } from '@/lib/resources/codelist';
import { request } from '@/lib/api';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/lib/store';
import { getFormError } from '@/lib/form';
import AlertZone from './AlertZone';
import { deliveryDetailsSchema } from './validations';

interface Props {
    token?: string | null;
    countries: CountryCodelist[];
    user: User | null;
}

const DeliveryDetailsForm: React.FC<Props> = (props) => {
    const { countries = [], user, token } = props;

    const { t } = useTranslation();

    const isDealer = user?.dealer && user.roles?.includes('ROLE_DEALER');

    const getDetails = () => {
        if (isDealer) {
            const dealer = user?.dealer as Dealer | null;
            return dealer?.deliveryDetails ?? {};
        }

        const client = user?.client as Client | null;
        return client?.deliveryDetails ?? {};
    };

    const formMethods = useForm<Company>({
        resolver: zodResolver(deliveryDetailsSchema),
        defaultValues: getDetails(),
        mode: 'onBlur',
    });

    const countryOptions = countries.map((country) => ({ text: country.name ?? '', value: country.id ?? '' }));

    useEffect(() => {}, [formMethods.formState.errors]);
    const getError = getFormError(t, formMethods.formState.errors);

    const { mutate, isPending, isSuccess, isError, error } = useMutation<unknown, unknown, Company>(
        {
            mutationKey: ['delivery', user?.id],
            mutationFn: (body) =>
                request(`/api/users/${user?.id}`, {
                    body: {
                        profile: {
                            deliveryDetails: body,
                        },
                    },
                    token,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                }),
        },
        queryClient,
    );

    const isDisabled = isPending;

    return (
        <FormProvider {...formMethods}>
            <form className="mt-6 rounded-xl bg-white p-8" onSubmit={formMethods.handleSubmit((data) => mutate(data))}>
                <div className="flex flex-col gap-6 lg:grid lg:grid-cols-6">
                    <Input
                        required
                        id="first_name"
                        disabled={isDisabled}
                        label={t('contact_form.first_name')}
                        placeholder={t('contact_form.first_name')}
                        error={getError('contactPersonFirstName')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonFirstName')}
                    />
                    <Input
                        required
                        id="last_name"
                        disabled={isDisabled}
                        label={t('contact_form.last_name')}
                        placeholder={t('contact_form.last_name')}
                        error={getError('contactPersonLastName')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonLastName')}
                    />
                    <Input
                        required
                        id="email"
                        type="email"
                        disabled={isDisabled}
                        label={t('contact_form.email')}
                        placeholder={t('placeholder.email')}
                        error={getError('contactPersonEmail')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonEmail')}
                    />
                    <PhoneInput
                        required
                        id="phone"
                        disabled={isDisabled}
                        label={t('contact_form.phone')}
                        placeholder={t('placeholder.phone')}
                        error={getError('contactPersonPhoneNumber')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonPhoneNumber')}
                    />
                    <Select
                        required
                        id="country"
                        disabled={isDisabled}
                        options={countryOptions}
                        label={t('address.country')}
                        defaultValue="/api/public/countries/CZ"
                        error={getError('address.country')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.country')}
                    />
                    <Input
                        required
                        id="street"
                        type="text"
                        disabled={isDisabled}
                        label={t('address.street')}
                        placeholder={t('placeholder.street')}
                        error={getError('address.street')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.street')}
                    />
                    <Input
                        required
                        type="text"
                        id="houseNumber"
                        disabled={isDisabled}
                        label={t('address.street_number')}
                        error={getError('address.houseNumber')}
                        placeholder={t('placeholder.street_number')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.houseNumber')}
                    />
                    <Input
                        id="note"
                        type="text"
                        disabled={isDisabled}
                        label={t('address.note')}
                        error={getError('address.address_note')}
                        placeholder={t('placeholder.address_note')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.note')}
                    />
                    <Input
                        required
                        id="zip"
                        type="text"
                        disabled={isDisabled}
                        label={t('address.zip')}
                        placeholder={t('placeholder.zip')}
                        error={getError('address.postcode')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.postcode')}
                    />
                    <Input
                        required
                        id="city"
                        type="text"
                        disabled={isDisabled}
                        label={t('address.city')}
                        placeholder={t('placeholder.city')}
                        error={getError('address.city')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.city')}
                    />
                </div>
                <div className="mt-6 flex flex-1 flex-col items-center gap-4 lg:flex-row">
                    <AlertZone isSuccess={isSuccess} isError={isError} />
                    <Button disabled={isDisabled} type="submit" className="ml-auto w-full lg:w-fit">
                        {t('save_changes')}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default DeliveryDetailsForm;
